import { render, staticRenderFns } from "./MyShoppingCart.vue?vue&type=template&id=9f0ed998&scoped=true&"
import script from "./MyShoppingCart.vue?vue&type=script&lang=js&"
export * from "./MyShoppingCart.vue?vue&type=script&lang=js&"
import style0 from "./MyShoppingCart.vue?vue&type=style&index=0&id=9f0ed998&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f0ed998",
  null
  
)

export default component.exports